import React from "react";
import {CircularProgress, Card, CardBody, CardFooter, Chip} from "@nextui-org/react";

export const Percentage = ({percentageValue}) => {
const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue((v) => (v >= percentageValue ? percentageValue : v + 5));
    }, 500);

    return () => clearInterval(interval);
  }, [percentageValue]);
  return (
    percentageValue ? <div>
    <CircularProgress
      aria-label="Loading..."
      size="lg"
      value={value}
      color="success"
      showValueLabel={true}
    />
     <Chip style={{marginTop:'10px'}} color="default">Plagarism Percentage</Chip>
    </div> : null
  );
}
