import { Input, Button, Chip } from "@nextui-org/react";
import styles from "./SignInForm.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setSignIn } from "../../redux/store/auth";
import { signIn } from "../../services/user";
import { toast } from "react-toastify";
import { setUser } from "../../redux/store/user";
import { forgotPassword } from "../../services/user";
import { validateEmail } from "../../utils/validate";

export const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isSignIn, setIsSignIn] = useState(true);
  const [forgotPasswordSuccess,setForgotPasswordSuccess] = useState(false);

  const handleOnSubmit = async () => {
    if (isSignIn) {
      if (email === "student@uta.com" && password === "password") {
        navigate('/student/dashboard');
        dispatch(setSignIn())
      } else if (email === "professor@uta.com" && password === "password") {
        navigate('/professor/dashboard');
        dispatch(setSignIn())
      } else if (email === "admin@uta.com" && password === "password") {
        navigate('/admin/professorsDetails');
        dispatch(setSignIn())
      } else {
        try{
        const { data } = await signIn({ email, password });
        console.log(data);
        if (data.status === "success") {
          console.log(data);
          toast.success(data.message);

          const { userType } = data.data.user;
          switch (userType) {
            case 0: navigate('/admin/professorsDetails')
              break;
            case 1: navigate('/student/dashboard')
              break;
            case 2: navigate('/professor/dashboard')
              break;
            default: break;
          }
          dispatch(setSignIn());
          dispatch(setUser(data.data.user));
        } else {
          toast.error(data.message);
          setIsInvalid(true);
        }
        }
        catch(error){
          console.log(error);
          toast.error("Failed to login, check your emailId & password")
        }
      }
    }
    else {
      const { data } = await forgotPassword(email);
      if (data.status === 'success') {
        toast.success(data.Message);
        setForgotPasswordSuccess(true);
      } else {
        toast.error(data.Message);
      }
    }
  }

  const handleOnForgotPassword = () => {
    setIsSignIn(false);
  }
  return (
    <div className={styles.wrapper}>
      {!isSignIn && <div className={styles.forgotHeader}> <h1>Forgot Password</h1>
      <br/>
      {forgotPasswordSuccess ? <p>Please check your mailbox for the new password</p> : <p>Please provide your email Id to recover your password</p> }
      </div> }
      {!forgotPasswordSuccess && <>
      <div className={styles.input}>
        <Input
          labelPlacement="outside"
          type="email"
          label="Email"
          errorMessage={!validateEmail(email) && "Please enter a valid email"}
          placeholder="Enter your email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {isSignIn && <div className={styles.input}>
        <Input
          labelPlacement="outside"
          type="password"
          label="Password"
          placeholder="Enter your password"
          onChange={(e) => setPassword(e.target.value)}
        /> 
      </div>}
          {isInvalid && <div className={styles.input}>
        <Chip radius="sm" size="md" color="danger">Please provide valid credentails</Chip>
      </div>}
      <div className={styles.button}>
        {isSignIn && <Button color="default" variant="bordered" style={{marginRight:'15px'}} onClick={handleOnForgotPassword} >
          Forgot Password
        </Button>}
        <Button color="primary" variant="shadow" isDisabled={isSignIn? !Boolean(email && password) : !Boolean(email)} onClick={handleOnSubmit}>
          {isSignIn ? 'Sign In' : 'Submit' }
        </Button>
      </div>
      </>}
    </div>
  )
}