import { Calendar } from '../pages/Students/Calendar/Calendar';
import { WeeklyProgressHub } from '../pages/Students/WeeklyProgressHub';
import { SupervisorInfo } from '../pages/Students/SupervisorInfo';
import { Immigration } from '../pages/Students/Immigration';
import { Chat } from '../pages/Students/Chat';
import { MyAccount } from '../pages/Students/MyAccount';

export const studentRoutes = () => { 
    const path = '/student';
    return [
    {
      path: `${path}/calendar`,
      element: <Calendar/>
    },
    {
      path: `${path}/weeklyProcessHub`,
      element: <WeeklyProgressHub/>
    },
    {
      path: `${path}/supervisorInfo`,
      element: <SupervisorInfo/>
    },
    {
      path: `${path}/immigration`,
      element: <Immigration/>
    },{
      path: `${path}/chat`,
      element: <Chat/>
    },{
      path: `${path}/myAccount`,
      element: <MyAccount/>
    },
  
  ]}