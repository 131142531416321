import React, { useEffect } from "react";
import styles from "./Queries.module.css";
import { Navbar } from "../../../components/admin/Navbar/Navbar";
import { useState } from "react";
import { toast } from "react-toastify";
import { getQueries, upateQueryStatus } from "../../../services/query";
import { Accordion, AccordionItem, Select, SelectItem  } from "@nextui-org/react";

export const Queries = () => {
  const [queries, setQueries] = useState([]);

  const status = [
    {
      key: "open",
      label: "Open",
    },
    {
      key: "wip",
      label: "Progress",
    },
    {
      key: "closed",
      label: "Closed",
    },
  ];

  const getAllQueries = async () => {
    try {
      const response = await getQueries();
      if (response.data.status === "success") setQueries(response.data.data);
    } catch (error) {
      toast.error("Failed to get the queries");
    }
  };

  useEffect(() => {
    getAllQueries();
  }, []);

  const handleOnChange = async (event, queryId) => {
    try {
      const { value } = event.target;
      const payload = {
        query_id: queryId,
        status: value,
      };
      const response = await upateQueryStatus(payload);
      if (response.data.status === "success"){
      toast.success("Successfully updated the status");
      getAllQueries()
    }
    } catch (error) {
      toast.error("Failed to upated the status");
    }
  };
  return (
    <div>
      <div className={styles.actionWrapper}>
        <div className={styles.professorName}>
          <h1>Welcome Admin</h1>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.navBar}>
          <Navbar />
        </div>
        <div className={styles.component}>
          <div className={styles.form}>
            <div className={styles.heading}>
              <h1>Queries</h1>
            </div>
            <div className={styles.subHeading}>
              <h2>Find all your queries</h2>
            </div>
            <Accordion variant="shadow">
              {queries.map((query) => (
                <AccordionItem
                  key={query.id}
                  aria-label={query.id}
                  title={
                    <h1 style={{ fontSize: "x-larger", fontWeight: "100" }}>
                      {query.name}{" "}
                      <p style={{ color: "blue" }}>{query.email}</p>
                    </h1>
                  }
                >
                  <div className={styles.info}>
                    <h3>Description</h3>
                    <p>{query.description}</p>
                  </div>
                  <div className={styles.info}>
                    <h3>Current Status</h3>
                    <p>{query.status}</p>
                  </div>

                  <div className={styles.info}>
                    <Select
                      className={styles.select}
                      label="Update Status"
                      placeholder="Update Status"
                      onChange={(event) => handleOnChange(event, query.id)}
                    >
                      {status.map((st) => (
                        <SelectItem key={st.key} value={st.key}>
                          {st.label}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};
