import React, { useEffect } from "react";
import { Select, SelectItem, Input, Button} from "@nextui-org/react";
import styles from './Assign.module.css';
import { Navbar } from "../../../components/admin/Navbar/Navbar";
import { getUserDetails } from "../../../services/user";
import { useState } from "react";
import { addSuperVisorDetails } from "../../../services/user";
import { toast } from 'react-toastify';
import { validateEmail } from "../../../utils/validate";

export const Assign = () => {
  const [professors, setProfessor] = useState([]);
  const [professorId, setProfessorId] = useState('');
  const [studentEmailId, setStudentEmailId] = useState('');

  const getProfessorDetails = async () => {
    const filter = { filterUserType: 2};
    const response = await getUserDetails(filter);
    console.log(response);
    setProfessor(response.data.data);
  }
  useEffect(() => {
    getProfessorDetails();
  },[]);

  const handleOnSubmit= async () => {
    const payload = {
      professorId,
      studentEmailId
    }
    try{
    const response = await addSuperVisorDetails(payload);
    console.log(response);
    if(response.data.status === "success"){
      toast.success(response.data.message);
      setStudentEmailId('');
    }else{
      toast.error(response.message)
    }}
    catch(error){
      toast.error(error.response.data.message)
    }
  }


  return (
    <div>
      <div className={styles.actionWrapper}>
        <div className={styles.professorName}>
          <h1>Welcome Admin</h1>
        </div>
       </div>
      <div className={styles.container}>
      <div className={styles.navBar}>
        <Navbar />
      </div>
      <div className={styles.component}>
      <div className={styles.form}>
            <div className={styles.heading}>
            <h1>
              Assign 
            </h1>
            </div>
            <div className={styles.subHeading}>
              <h2>
                Assign professor to the student.
              </h2>
            </div>
            <div className={styles.info}>
              <h3>Professors</h3>
              <div className={styles.reasons}>
                <Select
                  className={styles.select}
                  label="Professor"
                  placeholder="Select a Professor"
                  errorMessage={!professorId && 'Choose professor' }
                  onChange={(event) => setProfessorId(event.target.value)}
                >
                  {professors.map((professor) => (
                    <SelectItem key={professor.id} value={professor.id}>
                      {`${professor.firstName} ${professor.lastName}`}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.info}>
            <h3>Student Email Id</h3>
            <div className={styles.fileUpload}>
                <Input  className={styles.input} size="xs" value={studentEmailId} errorMessage={!validateEmail(studentEmailId) && 'Enter valid email address'} onChange={(event) => setStudentEmailId(event.target.value)}/>
                <Button variant="bordered" isDisabled={!(professorId && studentEmailId)} color="primary" onClick={handleOnSubmit}>Assign Professor</Button>
              </div>
            </div>
      </div>
      </div>
      </div>
      </div>
  );
}
