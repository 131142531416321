import React from "react";
import styles from "./CreateTask.module.css";
import { Navbar } from "../../../components/professor/Navbar/Navbar";
import { Input, Textarea, Button } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { createOrUpdateTask } from "../../../services/task";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import { getStudentDetailsByProfessor } from "../../../services/user";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";

export const CreateTask = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const professorDetails = useSelector((state) => state.user.details);
  const [studentIds, setStudentIds] = useState(new Set());
  const [students, setStudents] = useState([]);

  const getStudentDetails = async () => {
    console.log(professorDetails);
    const filter = {
      professor_id: professorDetails.id,
    };
    const response = await getStudentDetailsByProfessor(filter);
    console.log(response);
    if (response.data.status === "success") {
      const { data } = response.data;
      setStudents(data);
    } else {
      const { message } = response.data;
      toast.error(message);
    }
  };
  useEffect(() => {
    if (professorDetails.id) getStudentDetails();
  }, [professorDetails.id]);

  const handleOnSubmit = async () => {
    console.log(studentIds);
    const payload = {
      title,
      description,
      deadline,
      professorId: professorDetails.id,
      taskStatus: "open",
      studentIds: Array.from(studentIds).toString()
    };
    try {
      const response = await createOrUpdateTask(payload);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setDeadline("");
        setDescription("");
        setTitle("");
        setStudentIds(new Set());
      }
    } catch (error) {
      toast.error("Failed to submit the task");
    }
  };

  const isValid =  title && description && deadline;

  return (
    <ProfessorLayout>
        <div className={styles.component}>
          <div className={styles.form}>
            <div className={styles.heading}>
              <h1>Create Task</h1>
            </div>
            <div className={styles.subHeading}>
              <h2>Enter the details to create a new task</h2>
            </div>
            <div className={styles.info} style={{ marginTop: "40px" }}>
              <Input
                labelPlacement="outside"
                type="text"
                label="Title"
                errorMessage={!title && "Title required"}
                placeholder="Provide the task title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
            </div>
            <div className={styles.info}>
              <Textarea
                labelPlacement="outside"
                placeholder="Provide the task description"
                label="Description"
                errorMessage={!description && "Description required"}
                size="s"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
            <div className={styles.info}>
              <MultiSelect
                users={students}
                values={studentIds}
                errorMessage={!studentIds && "Add students"}
                setValues={setStudentIds}
              />
            </div>
            <div className={styles.info}>
              <Input
                type="date"
                label="Deadline"
                placeholder="Please choose the deadline date"
                errorMessage={!deadline && "Deadline is required"}
                value={deadline}
                onChange={(event) => setDeadline(event.target.value)}
              />
            </div>
            <div className={styles.info}>
              <Button
                color="primary"
                variant="bordered"
                style={{ float: "right" }}
                onClick={handleOnSubmit}
                isDisabled={!isValid}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
    </ProfessorLayout>
  );
};
