import React, { useState } from "react";
import { Button, Input } from "@nextui-org/react";
import styles from "./MyAccount.module.css";
import { useSelector } from "react-redux";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";
import { registration } from "../../../services/user";
import { toast } from "react-toastify";

export const MyAccount = () => {
  const professorDetails = useSelector((state) => state.user.details);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOnSubmit = async () => {
    try {
      const user = {
        ...professorDetails,
        password,
        userId:professorDetails.id
      };
      const response = await registration(user);
      if (response.data.status === "success") {
        toast.success("Successfully updated the password");
      } else {
        toast.warn("Failed to update password");
      }
    } catch (error) {
      toast.error("Failed to upload the password");
    }
  };

  const isValid = password && confirmPassword && password === confirmPassword;
  return (
    <ProfessorLayout>
      <div className={styles.component}>
        <div className={styles.form}>
          <div className={styles.heading}>
            <h1>My Account</h1>
          </div>
          <div className={styles.subHeading}>
            <h2>Know about your account details</h2>
          </div>
          <div className={styles.info}>
            <h3>First Name</h3>
            <p>{professorDetails.firstName}</p>
          </div>
          <div className={styles.info}>
            <h3>Last Name</h3>
            <p>{professorDetails.lastName}</p>
          </div>
          <div className={styles.info}>
            <h3>Designation</h3>
            <p>{professorDetails.designation}</p>
          </div>
          <div className={styles.info}>
            <h3>Phone Number</h3>
            <p>+1 {professorDetails.phone}</p>
          </div>
          <div className={styles.info}>
            <h3>Email</h3>
            <p>{professorDetails.email}</p>
          </div>
          <div className={styles.info}>
            <h3>Date of Birth</h3>
            <p>{professorDetails.dob}</p>
          </div>
          <div className={styles.info}>
            <h3>Update Password</h3>
            <div className={styles.input}>
              <div className={styles.subInput}>
                <Input
                  labelPlacement="inside"
                  type="password"
                  label="Password"
                  errorMessage={!password && "Enter the password"}
                  placeholder="Enter your password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              <div className={styles.subInput}>
                <Input
                  labelPlacement="inside"
                  type="password"
                  label="Confirm Password"
                  errorMessage={
                    confirmPassword !== password &&
                    "Please verify your password"
                  }
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  placeholder="Enter your password"
                />
              </div>
            </div>
            <div className={styles.button}>
              <Button
                color="primary"
                variant="shadow"
                isDisabled={!isValid}
                onClick={handleOnSubmit}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ProfessorLayout>
  );
};
