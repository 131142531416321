import { Accordion, AccordionItem, Link } from "@nextui-org/react";
import styles from "./History.module.css";
import { getWeeklyReports } from "../../services/task";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { Percentage } from "../Percentage/Percentage";

export const HistoryWPH = ({studentId, professorId, taskId}) => {

  const [weeklyReports, setWeeklyReports] = useState([]);
  const [error, setError] = useState("");
  const getWeeklyUserReports = async () => {
    try {
      const payload = {
        student_id: studentId,
        task_id: taskId,
        professor_id: professorId,
      };
      const response = await getWeeklyReports(payload);
      console.log(response);
      if (response.data.status === "success")
        setWeeklyReports(response.data.data);
      else setError(response.data.message);
    } catch (error) {
      toast.error("Failed to get weekly reports");
    }
  };

  useEffect(() => {
    getWeeklyUserReports();
  }, [professorId, studentId]);
  return !error ? (
    <Accordion variant="shadow">
      {!error &&
        weeklyReports.map((weeklyReport) => (
          <AccordionItem
            key={weeklyReport.id}
            aria-label={weeklyReport.request_type}
            title={
              <h1 style={{ fontSize: "x-larger", fontWeight: "100" }}>
                Week {weeklyReport.week_id}{" "}
                <p style={{ color: "blue" }}>
                  {" "}
                  Start: {weeklyReport.start_date} End: {weeklyReport.end_date}{" "}
                </p>
              </h1>
            }
          >
            <div className={styles.info}>
              <h3>Task</h3>
              <p>{weeklyReport.task_title}</p>
            </div>
            <div className={styles.info}>
              <h3>Comments</h3>
              <p>{weeklyReport.justification_comments}</p>
            </div>
            <div className={styles.info}>
              <h3>Professor Comments</h3>
              <p>
                {weeklyReport.professor_feedback
                  ? weeklyReport.professor_feedback
                  : "No Comments"}
              </p>
            </div>
            <div className={styles.info}>
              <Percentage percentageValue={weeklyReport.plag_percentage} />
            </div>
            <div className={styles.info}>
              <h3>Documents</h3>
              <Link href={weeklyReport.document_url} type="link">
                Download{" "}
              </Link>
            </div>
          </AccordionItem>
        ))}
    </Accordion>
  ) : (
    error
  );
};
