import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useEffect } from "react";
import { createReminder, getReminders } from "../../../services/calendar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { StudentLayout } from "../../../layouts/student/StudentLayout";
import styles from "./Calendar.module.css";
import { CreateReminder } from "../../../components/student/CreateReminder/CreateReminder";
import { Button } from "@nextui-org/react";

export const Calendar = () => {
  const studentDetails = useSelector((state) => state.user.details);
  const [reminders, setReminders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCalendarReminders = async () => {
    try {
      const response = await getReminders(studentDetails.id);
      if (response.data.status === "success") {
        setReminders(response.data.data);
      }
    } catch (error) {
      toast.warn("Failed to load reminders");
    }
  };

  useEffect(() => {
    getCalendarReminders();
  }, [studentDetails.id]);

  const handleCreateReminder = async (date, reminder) => {
    const payload = {
      user_id: studentDetails.id,
      reminder_date: date,
      reminder: reminder,
    };
    try {
      const response = await createReminder(payload);
      if (response.data.status === "success")
        toast.success(response.data.message);
    } catch (error) {
      toast.error("Failed to create reminder");
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <StudentLayout>
      <div className={styles.actionBar}>
        <div className={styles.createTask}>
          <Button color="primary" onClick={() => setIsModalOpen(true)}>
            Create Reminder
          </Button>
        </div>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        styles={{ minWidth: "-webkit-fill-available" }}
        events={reminders}
        className={styles.calendar}
      />
      <CreateReminder
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleSubmit={handleCreateReminder}
      />
    </StudentLayout>
  );
};
