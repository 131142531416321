import { StudentDetails } from '../pages/Admin/StudentsDetails';
import { ProfessorsDetails } from '../pages/Admin/ProfessorsDetails';
import { Assign } from '../pages/Admin/Assign'
import { Queries } from '../pages/Admin/Queries/Queries';

export const adminRoutes = () => {
  const path = '/admin';
  return [
    {
      path: `${path}/studentsDetails`,
      element: <StudentDetails />
    },
    {
      path: `${path}/professorsDetails`,
      element: <ProfessorsDetails />
    },
    {
      path: `${path}/assign`,
      element: <Assign />
    },
    {
      path: `${path}/queries`,
      element: <Queries />
    }
  ]
}