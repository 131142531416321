import { useEffect } from 'react';
import './App.css';
import AppRouter from './appRouter';
import { Layout } from './layouts/Layout';
import { useDispatch } from 'react-redux'
import { setUser } from './redux/store/user';
import { setSignIn, setSignOut } from './redux/store/auth';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const userDetails = sessionStorage.getItem('userDetails');
    const isSignedIn = sessionStorage.getItem('isSignedIn');
    isSignedIn && userDetails && dispatch(setUser(JSON.parse(userDetails)));
    isSignedIn ? dispatch(setSignIn()): dispatch(setSignOut())
  }, [dispatch]);

  return (
      <Layout>
        <AppRouter/>
      </Layout>
  );
}

export default App;
