import { axiosInstance } from "./config"

const getUrl = (url) => {
    return `recommendation/${url}`;
}

export const createOrUpdateRecommendation = async (payload) => {
    return await axiosInstance.post(getUrl('createorupdaterecommendation'), payload);
}

export const getRecommendationRequests = async (payload) => {
    return await axiosInstance.post(getUrl('getrecommendationrequests'), payload);
}