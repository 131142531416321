import React, { useEffect, useState } from "react";
import { Button, Select, SelectItem } from "@nextui-org/react";
import styles from "./Dashboard.module.css";
import { TaskCard } from "../../../components/Card";
import { Navbar } from "../../../components/professor/Navbar/Navbar";
import { useDispatch } from "react-redux";
import { setSignIn } from "../../../redux/store/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteTask, getTasks } from "../../../services/task";
import { toast } from "react-toastify";
import { EditTask } from "../../../components/professor/EditTask/EditTask";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const professorDetails = useSelector((state) => state.user.details);
  const [tasks, setTasks] = useState([]);
  const [task,setTask] = useState({});
  const [backupTasks, setBackupTasks] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    dispatch(setSignIn());
    if (professorDetails.id) getProfessorTasks();
  }, [professorDetails]);

  const getProfessorTasks = async () => {
    const filter = {
      professorId: professorDetails.id,
      studentId: null,
    };

    try {
      const response = await getTasks(filter);
      console.log(response);
      if (response.data.status === "success") {
        const { data } = response.data;
        setTasks(data);
        setBackupTasks(data);
      } else {
        const { message } = response.data;
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
      toast.warn(error.response.data.message);
      setError(error.response.data.message);
    }
  };

  const sortValues = [
    { label: "All", value: "all" },
    { label: "Deadline", value: "deadline" },
    { label: "Status", value: "status" },
  ];

  const handleOnSortChange = (event) => {
    if (event.target.value === "status")
      setTasks(
        [...tasks].sort((a, b) => (a.task_status > b.task_status ? -1 : 1))
      );
    else if (event.target.value === "deadline")
      setTasks([...tasks].sort((a, b) => (a.deadline > b.deadline ? -1 : 1)));
    else setTasks([...backupTasks]);
  };

  const handleOnDelete = async (task) => {
    try {
      const response = await deleteTask(task);
      if (response.data.status === "success") {
        toast.success("Task deleted successfully");
        getProfessorTasks();
      } else {
        toast.warn("Task deletion failed");
      }
    } catch (error) {
      toast.error("Task deletion failed");
    }
  };

  const handleOnEdit = async (task) => {
    setIsEditOpen(true);
    setTask(task);
  };

  const handleOnEditClose = () => {
    setIsEditOpen(false);
  }

  return (
    <ProfessorLayout>
      <div className={styles.actionWrapper}>
        <div className={styles.actionBar}>
          <div className={styles.sort}>
            <Select size="xs" label="Sort Type" onChange={handleOnSortChange}>
              {sortValues.map((sortValue) => (
                <SelectItem key={sortValue.value} value={sortValue.value}>
                  {sortValue.label}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className={styles.createTask}>
            <Button
              color="primary"
              onClick={() => navigate("/professor/createTask")}
            >
              Create Task
            </Button>
          </div>
        </div>
        </div>
        <div className={styles.component}>
          {error ? <p> 
            No Task found
          </p> :
          tasks.map((task) => (
            <div className={styles.card}>
              <TaskCard
                isProfessor={true}
                task={task}
                handleOnDelete={handleOnDelete}
                handleOnEdit={handleOnEdit}
              />
            </div>
          ))}
        </div>
      <EditTask isOpen={isEditOpen} onClose={handleOnEditClose} task={task} getProfessorTasks={getProfessorTasks}/>
      </ProfessorLayout>
  );
};
