import React from "react";
import { Select, SelectItem, Input, Button, Textarea } from "@nextui-org/react";
import styles from "./Immigration.module.css";
import { Navbar } from "../../../components/student/Navbar/Navbar";
import { BlobServiceClient } from "@azure/storage-blob";
import { useState, useEffect } from "react";
import config from "../../../utils/config";
import { useSelector } from "react-redux";
import { getSuperVisorDetails } from "../../../services/user";
import { createOrUpdateRecommendation } from "../../../services/recommendation";
import { toast } from 'react-toastify';
import { StudentLayout } from "../../../layouts/student/StudentLayout";
import { HistoryRecommendation } from "../../../components/student/HistoryRecommendation/HistoryRecommendation";
export const Immigration = () => {
  const reasons = [
    { id: 1, label: "Release", value: "release" },
    { id: 2, label: "To Recommend", value: "recommend" },
  ];
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [superVisor, setSuperVisor] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const studentDetails = useSelector((state) => state.user.details);
  const [files, setFiles] = useState([]);
  const [isHistoryView, setIsHistoryView] = useState(false);
  //const [fileURL, setFileURL] = useState("");

  const uploadImageToAzureBlobStorage = async (file) => {
    console.log(file);
    console.log(config);
    const blobServiceClient = BlobServiceClient.fromConnectionString(
      config.blob_connectionString
    );
    const containerClient = blobServiceClient.getContainerClient(
      config.blob_supported_docs_container_name
    );
    const blobName = `${Date.now()}-${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    blockBlobClient.uploadData(file);
  
    return blockBlobClient.url;
  };

  const getSuperVisorInfo = async () => {
    try {
      const response = await getSuperVisorDetails(studentDetails.id);
      console.log(response);
      setSuperVisor(response.data.data[0]);
    } catch (error) {
      console.log(error);
      const { message } = error.response.data;
      setErrorMessage(message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    const fileUrl = await uploadImageToAzureBlobStorage(files[0]);
    const payload = {
      student_id: studentDetails.id,
      professor_id: superVisor.Supervisor_Id,
      status: "open", //'open','accepted','rejected'
      supported_doc_url: fileUrl,
      request_type: reason, //types are 'recommend','release'
      student_comments: comment,
      professor_comments: null,
    };
    try{
    const response = await createOrUpdateRecommendation(payload);
    console.log(response);
    if(response.data[0].status === "success"){
      toast.success(response.data[0].message);
      setComment('');
      //setFileURL('');
      setFiles([]);
      setReason('');
    }}
    catch(error){
      toast.error("Failed to submit")
    }
  };

  useEffect(() => {
    if (studentDetails.id) getSuperVisorInfo();
  }, [studentDetails.id]);

  const isValid = reason && comment && files.length > 0;

  return (
      <StudentLayout>
        <div className={styles.component}>
          {!isHistoryView && <Button variant="shadow" color="primary" onClick={() => setIsHistoryView(true)}>History</Button> }
          <div className={styles.form}>
            <div className={styles.heading}>
              <h1>Immigration</h1>
            </div>
            <div className={styles.subHeading}>
              <h2>Know About your Immigration details</h2>
            </div>
            { isHistoryView ? <HistoryRecommendation studentId={studentDetails.id} />: <>
            <div className={styles.info}>
              <h3>Your visa status</h3>
              <p>{studentDetails.visaStatus}</p>
            </div>
            <div className={styles.info}>
              <h3>Your volunteer program status</h3>
              <p>{studentDetails.isReleased !== 0 ? 'Released' : 'In progress'}</p>
            </div>
            <div className={styles.info}>
              <h3>
                For release or recommendation letter from supervisor for any
                opportunities, please submit your request below
              </h3>
            </div>
            <div className={styles.info}>
              <h3>Reason</h3>
              <div className={styles.reasons}>
                <Select
                  className={styles.select}
                  label="Reason"
                  placeholder="Select a Reason"
                  value={reason}
                  errorMessage={!reason && "Select a reason"}
                  onChange={(event) => setReason(event.target.value)}
                >
                  {reasons.map((task) => (
                    <SelectItem key={task.value} value={task.value}>
                      {task.label}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.reasons}>
                <Textarea
                  label="Comments"
                  className={styles.description}
                  value={comment}
                  errorMessage={!comment && "Enter your comment"}
                  onChange={(event) => setComment(event.target.value)}
                  size="s"
                />
              </div>
            </div>
            <div className={styles.info}>
              <h3>Report</h3>
              <div className={styles.fileUpload}>
                <Input
                  type="file"
                  className={styles.input}
                  placeholder={"Add your comments"}
                  errorMessage={!files.length > 0 && "Choose a file to upload"}
                  size="xs"
                  onClick={(e) => {
                    console.log(e.target.files);
                    setFiles(e.target.files);
                  }}
                />
                {/* <Button
                  variant="bordered"
                  color="primary"
                  onClick={() => uploadImageToAzureBlobStorage(files[0])}
                >
                  File Upload
                </Button> */}
              </div>
            </div>
            <div className={styles.submit}>
              <Button variant="shadow" color="primary" isDisabled={!isValid} onClick={handleOnSubmit}>
                Submit
              </Button>
            </div>
            </> }
          </div>
        </div>
      </StudentLayout>
  );
};
