import { axiosInstance } from "./config";

const getUrl = (url) => {
    return `calender/${url}`;
}

export const getReminders = async (userId) => {
    return await axiosInstance.get(getUrl(`getreminders/${userId}`));
}

export const createReminder = async (payload) => {
    return await axiosInstance.post(getUrl('createreminder'), payload);
} 