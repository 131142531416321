import { useEffect, useCallback } from "react";
import { getRecommendationRequests } from "../../../services/recommendation";
import { Accordion, AccordionItem, Link } from "@nextui-org/react";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from './HistoryRecommendation.module.css';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { PDFDocument } from "../PDF/PDFDocument";
import { useRef } from "react";

export const HistoryRecommendation = ({ studentId, professorId, status }) => {
  const [recommendations, setRecommendations] = useState([]);
  const pdfRef = useRef();

  const getRecommendationHistory = useCallback(async () => {
    try {
      const payload = {
        professor_id: professorId,
        student_id: studentId,
        status: status,
      };
      const response = await getRecommendationRequests(payload);
      if (response.data.status === "success") {
        setRecommendations(response.data.data);
      }
    } catch (error) {
      toast.error("Failed to load recommendation history");
    }
  }, [studentId, professorId, status]);

  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do e";

  useEffect(() => {
    getRecommendationHistory();
  }, [getRecommendationHistory]);

  const getStatusColor = (status) => { switch(status) {
    case 'accepted' : return 'green';
    case 'rejected' : return 'red';
    default : return 'blue';
   } 
  }

  return (
    <Accordion variant="shadow">
      {recommendations.map((recommendation) => (
        <AccordionItem key={recommendation.id} aria-label={recommendation.request_type} title={<h1 style={{fontSize:'x-larger', fontWeight:'100'}}>{recommendation.request_type} <p style={{color:getStatusColor(recommendation.status)}}> {recommendation.status}</p></h1>}>
           <div className={styles.info}>
              <h3>
                Student Comments
              </h3>
              <p>{recommendation.student_comments}</p>
            </div>
            <div className={styles.info}>
              <h3>
                Professor Comments
              </h3>
              <p>{recommendation.professor_comments ? recommendation.professor_comments :'No Comments'}</p>
            </div>
            <div className={styles.info}>
              <h3>
                Documents
              </h3>
              <Link href={recommendation.supported_doc_url} type="link">Download </Link>
            </div>
            {recommendation.request_type === "reccomendation" && recommendation.status === "accepted" && 
            <div className={styles.info}>
            <h3>
              Download Recommendation Letter
            </h3>
            <PDFDownloadLink document={<PDFDocument recommendation={recommendation} />} fileName={`${String(recommendation.student_name).replaceAll(' ','_')}_recommendation.pdf`}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
      </PDFDownloadLink>
          </div>
            } 
        </AccordionItem>
      ))}
    </Accordion>
  );
};
