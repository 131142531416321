import React from 'react';
import { SignInPage, RegistrationPage, LandingPage } from './pages';
import { Dashboard as StudentDashboard } from './pages/Students';
import { Dashboard as ProfessorDashboard } from './pages/Professor';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ContactUs } from './pages/ContactUs';
import { AboutUs } from './pages/AboutUs';
import { studentRoutes } from './utils/studentRoutes';
import { professorRoutes } from './utils/professorRoutes';
import { adminRoutes } from './utils/adminRoutes';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage/>,
  },
  {
    path: "/signIn",
    element: <SignInPage/>,
  },
  {
    path: "/registration",
    element: <RegistrationPage/>,
  },
  {
    path: "/student/dashboard",
    element: <StudentDashboard/>,
  },
  {
    path: "/professor/dashboard",
    element: <ProfessorDashboard/>,
  },
  {
    path: "/contactUs",
    element: <ContactUs/>,
  },
  {
    path: "/aboutUs",
    element: <AboutUs/>,
  },
  ...studentRoutes(),
  ...professorRoutes(),
  ...adminRoutes()
]);
const AppRouter = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default AppRouter;
