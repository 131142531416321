import { Image } from "@nextui-org/react";
import { RegistrationForm } from "../../components/RegistrationForm/RegistrationForm";
import styles from "./RegistrationPage.module.css";

export const RegistrationPage = () => {
  return (
    <div className={styles.registrationForm}>
      <h2 className={styles.rws}>Register with us</h2>
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <Image src={"/register.jpg"} />
        </div>
        <div className={styles.form}>
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};
