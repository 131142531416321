import React from "react";
import { Button, Input, Snippet, Select, SelectItem } from "@nextui-org/react";
import styles from "./Chat.module.css";
import { useRef, useEffect, useState } from "react";
import io from "socket.io-client";
import { getUserDetails } from "../../services/user";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const ChatApp = () => {
  const [recipients, setRecipients] = useState([]);
  const socketRef = useRef();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const senderDetails = useSelector((state) => state.user.details);
  const [recipientName, setRecipientName] = useState("");
  const senderName = `${senderDetails.firstName} ${senderDetails.lastName}`;

  useEffect(() => {
    socketRef.current = io("https://isvpchatserver.azurewebsites.net");

    // Emit 'login' event to the server
    socketRef.current.emit("login", senderName);

    // Event listener for receiving messages
    socketRef.current.on("message", (data) => {
      console.log(data);
      setMessages((prevMessages) => [...prevMessages, JSON.parse(data)]);
    });

    // Clean up the event listener and disconnect on unmount
    return () => {
      socketRef.current.disconnect();
    };
  }, [senderName]);

  const getRecipientsDetails = async () => {
    const filter = {
      filterId: null,
      filterUserType: null,
    };
    try {
      const response = await getUserDetails(filter);
      if (response.data.status === "success") {
        setRecipients(response.data.data);
      }
    } catch (error) {
      toast.error("Failed to get recipients");
    }
  };

  useEffect(() => {
    getRecipientsDetails();
  }, []);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  console.log(messages);
  const handleSendMessage = () => {
    if (message.trim() !== "") {
      const newMessage = {
        sender_id: senderName,
        receiver_id: recipientName,
        message: message,
        sent_at: new Date().toISOString(), // Convert date to ISO string
      };

      // Emit the 'message' event to the server
      socketRef.current.emit("message", JSON.stringify(newMessage));
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setMessage("");
    }
  };
  console.log(messages);
  return (
    <div className={styles.component}>
      <div className={styles.form}>
        <div className={styles.heading}>
          <h1>Conversations</h1>
        </div>
        <div className={styles.subHeading}>
          <h2>Let's connect with people</h2>
        </div>
        <div className={styles.chatContainer}>
          <div className={styles.task}>
            <Select
              placeholder="Select a Recipient"
              label="Recipients"
              onChange={(event) => setRecipientName(event.target.value)}
            >
              {recipients.map((recipient) => (
                <SelectItem
                  key={`${recipient.firstName} ${recipient.lastName}`}
                  value={`${recipient.firstName} ${recipient.lastName}`}
                >
                  {`${recipient.firstName} ${recipient.lastName}`}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className={styles.chatBox}>
            {messages.map((data) => (
              senderName === data.sender_id ? 
              <div className={`${styles.chatMessage} ${styles.sent}`}>
              <Snippet
                hideCopyButton
                hideSymbol
                color="primary"
                variant="solid"
              >
                {data.message}
              </Snippet>
              <span style={{ fontSize: "x-small", color: "grey", display: "block" }}>you</span>
            </div>:
             <div className={`${styles.chatMessage} ${styles.received}`}>
             <Snippet
               hideCopyButton
               hideSymbol
               color="default"
               variant="solid"
             >
               {data.message}
             </Snippet>
             <span style={{ fontSize: "x-small", color: "grey", display: "block" }}>{data.sender_id}</span>
           </div>
            ))}
           
            
          </div>
          <div className={styles.input}>
            <Input
              type="text"
              placeholder="Type your message..."
              size="xs"
              value={message}
              onChange={handleMessageChange}
            />
            <Button
              color="primary"
              variant="bordered"
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
