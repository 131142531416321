import { Header } from "../components/Header/Header"
import { Footer } from "../components/Footer/Footer"
import styles from "./Layout.module.css"
export const Layout = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <Header />
            <div className={styles.childrenWrapper}>
                {children}
            </div>
            <Footer />
        </div>
    )
}