import React, { useState } from "react";
import { Select, SelectItem } from "@nextui-org/react";
import styles from "./Dashboard.module.css";
import { TaskCard } from "../../../components/Card";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSignIn } from "../../../redux/store/auth";
import { StudentLayout } from "../../../layouts/student/StudentLayout";
import { useSelector } from 'react-redux';
import { getTasks } from "../../../services/task";
import { toast } from "react-toastify";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const studentDetails = useSelector((state) => state.user.details);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState('');
  const [backupTasks, setBackupTasks] = useState([]);

  const getStudentTasks = async () => {
    const filter = {
      professorId: null,
      studentId: studentDetails.id
    }
    try{
    const response = await getTasks(filter);
    if(response.data.status === "success"){
      const { data } = response.data;
      setTasks(data);
      setBackupTasks(data);
    }else{
      const { message } = response.data;
      toast.error(message);
    }
  }catch(error){
    console.log(error);
    setError(error.response.data.message);
  }
  } 

  useEffect( () => {
    dispatch(setSignIn());
    if(studentDetails.id) getStudentTasks();
  },[studentDetails])

  const sortValues = [
    { label: "All", value: "all" },
    { label: "Deadline", value: "deadline" },
    { label: "Status", value: "status" },
  ];

  const handleOnSortChange = (event) => {
    if(event.target.value === 'status')
      setTasks([...tasks].sort((a,b) => a.task_status > b.task_status ? -1 : 1));
    else if(event.target.value === 'deadline')
      setTasks([...tasks].sort((a,b) => a.deadline > b.deadline ? -1 : 1));
    else
      setTasks([...backupTasks]);
  } 

  return (
    <StudentLayout>
      <div className={styles.actionBar}>
          {!error && <div className={styles.sort}>
            <Select size="xs" aria-label={"Sort Type"} placeholder="Sort Type" onChange={handleOnSortChange}>
              {sortValues.map((sortValue) => (
                <SelectItem key={sortValue.value} value={sortValue.value}>
                  {sortValue.label}
                </SelectItem>
              ))}
            </Select>
          </div> }
          <div className={styles.createTask}>
            {/* <Button color="primary">
        Create Task
      </Button> */}
          </div>
        </div>
        {error ? <div className={styles.component}> 
        <h1>
             {error }
        </h1>
        </div> :
      <div className={styles.component}>
        {tasks.map((task,index) => (
          <div className={styles.card} key={`${task.id}_${index}`} >
            <TaskCard isProfessor={false} task={task}/>
          </div>
        ))}
      </div> }
    </StudentLayout>
  );
};
