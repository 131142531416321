import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
} from "@nextui-org/react";
import styles from "./CreateReminder.module.css";
import { useState } from "react";

export const CreateReminder = ({ isOpen, onClose, handleSubmit }) => {
  const [date, setDate] = useState("");
  const [ reminder, setReminder] = useState("");

  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxWidth: "56rem" }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Create Reminder
            </ModalHeader>
            <ModalBody>
              <div>
                <div className={styles.input}>
                  <div className={styles.subInput}>
                    <Input
                      labelPlacement="outside"
                      type="date"
                      label="Select Date"
                      placeholder="Select date to create reminder"
                      value={date}
                      errorMessage={!date && "Please enter a valid date"}
                      name="dob"
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div className={styles.subInput}>
                    <Input
                      labelPlacement="outside"
                      type="text"
                      label="Reminder"
                      placeholder="Enter reminder"
                      name="gradDate"
                      value={reminder}
                      errorMessage={!reminder && "Please enter reminder text"}
                      onChange={(e) =>
                        setReminder(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              
                <Button color="primary" isDisabled={!Boolean(date && reminder)} onPress={() => handleSubmit(date, reminder)}>
                  Submit
                </Button>
              
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
