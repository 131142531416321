import React, { useState } from "react";
import styles from "./ProfessorsDetails.module.css";
import { Navbar } from "../../../components/admin/Navbar/Navbar";
import { ProfessorsDetailsTable } from "../../../components/admin/ProfessorsDetailsTable";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSignIn } from "../../../redux/store/auth";
import { Button, Input } from "@nextui-org/react";
import { getUserDetails } from "../../../services/user";
import { toast } from "react-toastify";
import { CreateOrUpdate } from "../../../components/admin/CreateOrUpdateUser/CreateOrUpdate";
import { registration } from "../../../services/user";

export const ProfessorsDetails = () => {
  const dispatch = useDispatch();
  const [professors, setProfessors] = useState([]);
  const [backupProfessors, setBackProfessors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [professor, setProfessor] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(setSignIn());
    getProfessorDetails();
  }, []);

  const getProfessorDetails = async () => {
    const filter = {
      filterId: null,
      filterUserType: 2,
    };
    const response = await getUserDetails(filter);
    console.log(response);
    if (response.data.status === "success") {
      const { data } = response.data;
      setProfessors(data);
      setBackProfessors(data);
    } else {
      const { message } = response.data;
      toast.error(message);
    }
  };

  const handleOnEdit = (professor) => {
    setProfessor(professor);
    setIsModalOpen(true);
    setModalType("Update");
  };

  const handleOnDelete = async (professor) => {
    const { id, _professor} = professor;
    const payload = {
      ..._professor,
      isDeleted: 1,
      userId: id,
    };
    try {
      const response = await registration(payload);
      if (response.data.status === "success") {
        toast.success("Successfully deleted the user");
        getProfessorDetails();
      }
    } catch (error) {
      toast.error("Failed to delete the user");
    }
  };

  const handleOnView = (professor) => {
    setProfessor(professor);
    setIsModalOpen(true);
    setModalType("View");
  };

  const handleOnCreate = () => {
    setProfessor({});
    setIsModalOpen(true);
    setModalType("Create");
  };

  const handleOnSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
    setProfessors(
      [...backupProfessors].filter(
        (professor) =>
          String(professor.firstName).includes(value) ||
          String(professor.lastName).includes(value) ||
          String(professor.dob).includes(value) ||
          String(professor.designation).includes(value) ||
          String(professor.email).includes(value) ||
          String(professor.phone).includes(value)
      )
    );
  };

  const handleOnSearchClear = () => {
    setProfessors(backupProfessors);
    setSearchText("");
  };

  return (
    <div>
      <div className={styles.actionWrapper}>
        <div className={styles.professorName}>
          <h1>Welcome Admin</h1>
        </div>
        <div className={styles.actionBar}></div>
      </div>
      <div className={styles.container}>
        <div className={styles.navBar}>
          <Navbar />
        </div>
        <div className={styles.component}>
          <div className={styles.form}>
            <div className={styles.heading}>
              <h1>Professors Details</h1>
            </div>
            <div className={styles.subHeading}>
              <h2>Know About Professors</h2>
            </div>
            <div className={styles.filter}>
              <div className={styles.create}>
                <Button
                  variant="solid"
                  size="xs"
                  color="primary"
                  onClick={handleOnCreate}
                >
                  Create
                </Button>
              </div>
              <div className={styles.search}>
                <div>
                  <Input
                    size="xs"
                    placeholder="Search for professor"
                    value={searchText}
                    onChange={handleOnSearch}
                  />
                </div>
                <div>
                  <Button
                    variant="bordered"
                    color="primary"
                    onClick={handleOnSearchClear}
                  >
                    {" "}
                    Clear{" "}
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.info}>
              <ProfessorsDetailsTable
                professors={professors}
                onEdit={handleOnEdit}
                onDelete={handleOnDelete}
                onView={handleOnView}
              />
            </div>
          </div>
        </div>
      </div>
      <CreateOrUpdate
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalType={modalType}
        user={professor}
        userType={"Professor"}
        getUserDetails={getProfessorDetails}
      />
    </div>
  );
};
