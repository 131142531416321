import { Select, Avatar, SelectItem, Chip } from '@nextui-org/react';

export const MultiSelect = ({users, setValues, values}) => {
    return (
        <Select
        items={users}
        label="Assigned to"
        variant="bordered"
        isMultiline={true}
        selectionMode="multiple"
        placeholder="Select a user"
        labelPlacement="outside"
        onSelectionChange={setValues}
        selectedKeys={values}
        classNames={{
          base: "max-w-m",
          trigger: "min-h-unit-12 py-2",
        }}
        renderValue={(items) => {
          return (
            <div className="flex flex-wrap gap-2">
              {items.map((item) => (
                <Chip key={item.key}> {item.data.first_name }</Chip>
              ))}
            </div>
          );
        }}
      >
        {(user) => (
          <SelectItem key={user.student_id} textValue={user.first_name}>
            <div className="flex gap-2 items-center">
              <Avatar alt={user.first_name} className="flex-shrink-0" size="sm" src={"https://d2u8k2ocievbld.cloudfront.net/memojis/male/1.png"} />
              <div className="flex flex-col">
                <span className="text-small">{`${user.first_name} ${user.last_name}`}</span>
                <span className="text-tiny text-default-400">{user.email}</span>
              </div>
            </div>
          </SelectItem>
        )}
      </Select>
    )
}