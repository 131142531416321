import { Listbox, ListboxItem} from "@nextui-org/react";
import {IconWrapper} from "../../../icons/IconWrapper";
import {ItemCounter} from "./ItemCounter";
import {BugIcon} from "../../../icons/BugIcon";
import {ChatIcon} from "../../../icons/ChatIcon";
import {LayoutIcon} from "../../../icons/LayoutIcon";
import {UsersIcon} from "../../../icons/UsersIcon";
import {WatchersIcon} from "../../../icons/WatchersIcon";
import {BookIcon} from "../../../icons/BookIcon";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();
    return (

      <Listbox
      aria-label="User Menu"
      onAction={(key) => navigate(`/professor/${key}`)}
      className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxItem
        key="dashboard"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-success/10 text-success">
            <BugIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Tasks
      </ListboxItem>
      <ListboxItem
        key="studentsDetails"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <UsersIcon />
          </IconWrapper>
        }
      >
        Students Details
      </ListboxItem>
      <ListboxItem
        key="assesmentCenter"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <LayoutIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Assesment Center
      </ListboxItem>
      <ListboxItem
        key="rlGenerator"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-danger/10 text-danger dark:text-danger-500">
            <BookIcon />
          </IconWrapper>
        }
      >
        Recommendation Letter Generator
      </ListboxItem>
      <ListboxItem
        key="weeklyProgressHistory"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-danger/10 text-danger dark:text-danger-500">
            <BookIcon />
          </IconWrapper>
        }
      >
        Weekly Progress History
      </ListboxItem>
      
      <ListboxItem
        key="chat"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <ChatIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Chat
      </ListboxItem>
      <ListboxItem
        key="myAccount"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <WatchersIcon />
          </IconWrapper>
        }
      >
        My Account
      </ListboxItem>
    </Listbox>
    )
}