import React, { useEffect } from "react"
import styles from './landingPage.module.css';
import { Button, Link, Image } from "@nextui-org/react";
import { useDispatch } from "react-redux";
import { setSignOut } from "../../redux/store/auth";
export const LandingPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSignOut());
    },[]);
    return (
        <div className={styles.landingPage}>
            <h1>
                Welcome to the International Student volunteer Program 
            </h1>
            <div className={styles.landingPageInfo}>
            <div className={styles.image}>
                <Image src={'/uta-home.jpeg'} width={'500px'} height={'300px'} />
            </div>
            <div className={styles.info}>
                <p>
                Amidst economic difficulties, numerous students encounter difficulties in finding employment post-graduation, a challenge accentuated for international students navigating distinct obstacles in the job sphere. In response, the government has introduced an extensive volunteer program aimed at offering assistance. This program spans multiple departments, with professors assuming key responsibilities in overseeing volunteers and evaluating their progress. Today, we explore the evolution of this impactful project.
                </p>
                <p> To know more about the <b>International Students volunteer program</b></p>
                <Button className={styles.signUp} as={Link} color="primary" href={'/registration'} variant="solid">
            Register
          </Button>
            </div>
            </div>
            <h2> "Your success—right here on campus and in everything that comes after—is our first priority. Take the next steps toward your future with us."</h2>
        </div>
    )
}