import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSignedIn:false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSignIn: (state) => {
      state.isSignedIn = true;
      sessionStorage.setItem('isSignedIn', true);
    },
    setSignOut: (state) => {
      state.isSignedIn = false;
      sessionStorage.removeItem('isSignedIn');
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSignIn, setSignOut } = authSlice.actions

export default authSlice.reducer;