import React, { useEffect, useState } from "react";
import styles from './SupervisorInfo.module.css';
import { getSuperVisorDetails } from "../../../services/user";
import { useSelector } from "react-redux";
import { StudentLayout } from "../../../layouts/student/StudentLayout";

export const SupervisorInfo = () => {
  const [superVisor, setSuperVisor] = useState({}); 
  const userDetails = useSelector((state) => state.user.details);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const getSuperVisorInfo = async () => {
    try{
    const response = await getSuperVisorDetails(userDetails.id);
    console.log(response);
    setSuperVisor(response.data.data[0]);
    }
    catch(error){
      console.log(error);
      const { message } =  error.response.data;
      setErrorMessage(message);
    }finally{
      setLoading(false);
    }
  } 

  useEffect(() => {
    getSuperVisorInfo();
  },[])

  return (
    <StudentLayout>
      { loading ? <></> : errorMessage ?
      <div className={styles.component}>
      <div className={styles.form}>
        <div className={styles.heading}>
        <h1>
          Super Visor Info
        </h1>
        </div>
        <div className={styles.subHeading}>
              <h2>
                {errorMessage}
              </h2>
            </div>
      </div>
      </div>
       : 
      <div className={styles.component}>
          <div className={styles.form}>
            <div className={styles.heading}>
            <h1>
              Super Visor Info
            </h1>
            </div>
            <div className={styles.subHeading}>
              <h2>
                Know About your SuperVisor
              </h2>
            </div>
            <div className={styles.info}>
              <h3>
                Professor Name
              </h3>
              <p>{`${superVisor.Supervisor_FirstName} ${superVisor.Supervisor_LastName}`}</p>
            </div>
            <div className={styles.info}>
              <h3>
                Designation
              </h3>
              <p>{superVisor.Supervisor_Designation}</p>
            </div>
            <div className={styles.info}>
              <h3>
                Email
              </h3>
              <p>{superVisor.Supervisor_Email}</p>
            </div>
            <div className={styles.info}>
              <h3>
                Phone Number
              </h3>
              <p>+ {superVisor.Supervisor_Phone}</p>
            </div>
            <div className={styles.info}>
              <h3>
                Work Hours
              </h3>
              <p>9:00AM-6:AM</p>
            </div>
          </div>
      </div> }
      </StudentLayout>
  );
}
