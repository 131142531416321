import React from "react";
import styles from "./AboutUs.module.css";
import { Button, Link, Image } from "@nextui-org/react";
export const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <h1>About Us</h1>
      <div className={styles.aboutUsInfo}>
        <div className={styles.image}>
          <Image
            src={"/uta-landing-page.png"}
            width={"500px"}
            height={"300px"}
          />
        </div>
        <div className={styles.info}>
          <p>
            Our endeavor is committed to fostering the success of international
            students by establishing well-defined goals aimed at creating
            significant outcomes. Through deliberate planning and
            implementation, our focus is on equipping students with the tools to
            thrive both academically and professionally, nurturing an
            environment where accomplishment and progress are celebrated and
            encouraged within our community.
          </p>
          <Button
            className={styles.signUp}
            as={Link}
            color="primary"
            href={"/registration"}
            variant="solid"
          >
            Learn More
          </Button>
        </div>
      </div>
      <h2>
        {" "}
        "Your success—right here on campus and in everything that comes after—is
        our first priority. Take the next steps toward your future with us."
      </h2>
    </div>
  );
};
