import React from "react";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";
import { ChatApp } from "../../../components/Chat";

export const Chat = () => {

  return (
    <ProfessorLayout>
      <ChatApp />
    </ProfessorLayout>
  );
};
