import React from "react";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination, Spinner,User, Chip, Tooltip} from "@nextui-org/react";
import { EditIcon } from "../../../icons/EditIcon";
import { DeleteIcon } from "../../../icons/DeleteIcon";
import { EyeIcon } from "../../../icons/EyeIcon";

export const StudentDetailsTable = ({students, onDelete, onEdit, onView}) => {
  const [page, setPage] = React.useState(1);

  const statusColorMap = {
    active: "success",
    paused: "danger",
    vacation: "warning",
  };

  const renderCell = React.useCallback((user, columnKey) => {
    console.log(columnKey);
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "email":
        return (
          <User
            avatarProps={{radius: "lg", src: user.avatar}}
            description={user.email}
            name={cellValue}
          >
            {user.email}
          </User>
        );
      case "role":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-sm capitalize">{cellValue}</p>
            <p className="text-bold text-sm capitalize text-default-400">{user.team}</p>
          </div>
        );
      case "status":
        return (
          <Chip className="capitalize" color={statusColorMap[user.status]} size="sm" variant="flat">
            {cellValue}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="View User">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => onView(user)}>
                <EyeIcon />
              </span>
            </Tooltip>
            <Tooltip content="Edit user">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => onEdit(user)}>
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete user">
              <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => onDelete(user)}>
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const rowsPerPage = 10;

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return students.slice(start, end);
  }, [page, students]);

  const totalPages = Math.ceil(students.length / rowsPerPage);

  return (
    <Table
      aria-label="Example table with client async pagination"
      bottomContent={
        totalPages > 0 ? (
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              page={page}
              total={totalPages}
              onChange={(page) => setPage(page)}
            />
          </div>
        ) : null
      }
    >
      <TableHeader>
        <TableColumn key="firstName">First Name</TableColumn>
        <TableColumn key="lastName">Last Name</TableColumn>
        <TableColumn key="email">Email</TableColumn>
        <TableColumn key="phone">Phone</TableColumn>
        <TableColumn key="dob">Date of Birth</TableColumn>
        <TableColumn key="gradDate">Year of Graduation</TableColumn>
        <TableColumn key="actions">Actions</TableColumn>
      </TableHeader>
      <TableBody
        items={ items ?? []}
        loadingContent={<Spinner />}
      >
        {(student) => (
          <TableRow key={student?.email}>

            {(columnKey) => <TableCell>{renderCell(student, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
