import { Listbox, ListboxItem} from "@nextui-org/react";
import {IconWrapper} from "../../../icons/IconWrapper";
import {ItemCounter} from "./ItemCounter";
import {TagIcon} from "../../../icons/TagIcon";
import { UsersIcon } from "../../../icons/UsersIcon";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();
    return (

      <Listbox
      aria-label="User Menu"
      onAction={(key) => navigate(`/admin/${key}`)}
      className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxItem
        key="studentsDetails"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <UsersIcon />
          </IconWrapper>
        }
      >
        Students Details
      </ListboxItem>
      <ListboxItem
        key="professorsDetails"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <UsersIcon />
          </IconWrapper>
        }
      >
        Professors Details
      </ListboxItem>
      <ListboxItem
        key="assign"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <TagIcon />
          </IconWrapper>
        }
      >
        Assign
      </ListboxItem>
      <ListboxItem
        key="queries"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <TagIcon />
          </IconWrapper>
        }
      >
        Queries
      </ListboxItem>
    </Listbox>
    )
}