import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
const date = new Date();

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
      paddingTop: 0
    },
    header: {
      marginBottom: 10,
    },
    logo: {
      marginBottom: 10,
    },
    title: {
      marginBottom: 20,
    },
    content: {
      marginBottom: 20,
    },
    footer: {
      marginTop: 20,
    },
  });
export const PDFDocument = ( {recommendation} ) => <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image src="https://saisvp.blob.core.windows.net/isvp-supporteddocs/uta_letter.png" style={{width:"300px",height:"60px"}}/>
          </View>
        </View>
        <View style={styles.title}>
          <Text style={{ fontSize: 24, fontWeight: "bold", textAlign:'center'}}>
            Recommendation Letter
          </Text>
        </View>
        <View style={styles.content}>
          <Text>
            <Text style={{ textDecoration: "underline" , textAlign:'center'}}>
              To Whom It May Concern,
            </Text>
            {"\n"}
            {"\n"}I am writing to recommend {recommendation.student_name} for
            your company. As a {recommendation.professor_designation} at
            University of Texas at Arlington, I have had the pleasure of
            Volunteering {recommendation.student_name} in ISV Program and have
            been consistently impressed by their dedication and hard work.{"\n"}
            {"\n"}
            {recommendation.student_name} has always shown a keen interest and
            has consistently demonstrated a deep understanding of the subject
            matter. Their ability to grasp complex concepts and apply them in
            practical situations is truly commendable.{"\n"}
            {"\n"}
            Furthermore, {recommendation.student_name} has excellent
            communication skills and has always been able to articulate their
            thoughts and ideas clearly and effectively. They are a team player
            and have always been willing to help their peers and contribute to
            the class discussions.{"\n"}
            {"\n"}I have no doubt that {recommendation.student_name} will be a
            valuable asset to your company and I highly recommend them for the
            same. If you require any further information, please do not hesitate
            to contact me.{"\n"}
            {"\n"}
            Sincerely,{"\n"}
            {recommendation.professor_name}
            {"\n"}
            {recommendation.professor_designation}
          </Text>
        </View>
        <View style={styles.footer}>
          <Text>{date.toDateString()}</Text>
        </View>
      </Page>
    </Document>
