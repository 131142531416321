import { Button, Input } from "@nextui-org/react";
import React, { useState } from "react";
import styles from "./RegistrationForm.module.css";
import { registration } from "../../services/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { validatePhone } from "../../utils/validate";
import { validateEmail } from "../../utils/validate";

export const RegistrationForm = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const _user = {...user, userType: 1}
         
      const { data } = await registration(_user);
      if(data.status === "success"){
        toast.success(data.message);
        navigate('/signIn');
      }else{
        toast.error(data.message);
      } 
   
  }

  const isValid = user.firstName && user.lastName && validatePhone(user.phone) && validateEmail(user.email) && user.dob && user.gradDate && user.password && user.password === user.confirmPassword ;
  return (
    <div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="text"
            label="First Name"
            name="firstName"
            placeholder="Enter your first name"
            errorMessage={!user.firstName && "Firstname is required"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="text"
            label="LastName"
            name="lastName"
            placeholder="Enter your last name"
            errorMessage={!user.lastName && "Lastname is required"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="number"
            label="Phone"
            placeholder="Enter your phone number"
            name="phone"
            errorMessage={!validatePhone(user.phone) && "Phone number should be 10 digits"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="email"
            label="Email"
            placeholder="Enter your email"
            errorMessage={!validateEmail(user.email) && "Enter valid Email Id"}
            name="email"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="password"
            label="Password"
            name="password"
            value={user.password}
            errorMessage={!user.password && "password required"}
            placeholder="Enter your password"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="password"
            label="Confirm Password"
            placeholder="Enter your password"
            name="confirmPassword"
            value={user.confirmPassword}
            errorMessage={!(user.confirmPassword === user.password)&& "Verify password"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="date"
            label="Date of Birth"
            errorMessage={!user.dob && "Date of birth required"}
            placeholder="Enter your DOB"
            name="dob"
            value={user.dob}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="date"
            label="Graduate Year"
            placeholder="Enter your password"
            name="gradDate"
            value={user.gradDate}
            errorMessage={!user.gradDate && "Gradutaion date required"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.button}>
        <Button color="primary" variant="shadow" isDisabled={!isValid} onClick={handleSubmit}>
          Register
        </Button>
      </div>
    </div>
  );
};
