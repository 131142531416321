import { HistoryWPH } from "../../../components/HistoryWPH/HistoryWPH";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";
import { useSelector } from "react-redux";
import styles from "./WeeklyProgressHistory.module.css";
export const WeeklyProgressHistory = () => {
  const professorDetails = useSelector((state) => state.user.details);
  return (
    <ProfessorLayout>
      <div className={styles.component}>
        <div className={styles.form}>
          <div className={styles.heading}>
            <h1>Weekly Progress History</h1>
          </div>
          <div className={styles.subHeading}>
            <h2>All weekly reports</h2>
          </div>
          <HistoryWPH
            studentId={null}
            professorId={professorDetails.id}
            taskId={null}
          />
        </div>
      </div>
    </ProfessorLayout>
  );
};
