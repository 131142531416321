import React from "react";
import styles from "./AssesmentCenter.module.css";
import {
  Select,
  SelectItem,
  Accordion,
  AccordionItem,
  Textarea,
  Button,
  Link,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import { getTasks } from "../../../services/task";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getWeeklyReports } from "../../../services/task";
import { createOrUpdateFeedback } from "../../../services/task";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";

export const AssesmentCenter = () => {
  const professorDetails = useSelector((state) => state.user.details);
  const [backupTasks, setBackupTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [assesments, setAssesments] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [taskId, setTaskId] = useState('');

  useEffect(() => {
    if (professorDetails.id) getProfessorTasks();
  }, [professorDetails]);

  const getProfessorTasks = async () => {
    const filter = {
      professorId: professorDetails.id,
      studentId: null,
    };

    try {
      const response = await getTasks(filter);
      console.log(response);
      if (response.data.status === "success") {
        const { data } = response.data;
        setTasks(data);
        setBackupTasks(data);
      } else {
        const { message } = response.data;
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
      toast.warn(error.response.data.message);
    }
  };

  const handleOnSelectChange = async (event) => {
    const { value } = event.target;

    const payload = {
      student_id: null,
      task_id: 6,
      professor_id: null,
    };
    try {
      const response = await getWeeklyReports(payload);
      console.log(response);
      if (response.data.status === "success") {
        setAssesments(response.data.data);
        setTaskId(6)// setTaskId(value)
      }
    } catch (error) {
      setAssesments([]);
      toast.error("Failed to fetch weekly reports");
    }
  };

  const handleSubmitFeedback = async (assesment) => {
    const payload = {
      id: -1,
      weekly_report_id: assesment.weekly_report_id,
      feedback: feedback,
      timestamp:null,
      professor_id: assesment.professor_id,
    };
    try {
      const response = await createOrUpdateFeedback(payload);
      if(response.data.status === "success"){
        toast.success(response.data.message)
        const event = {
          target:{
            value: taskId
          }
        }
        handleOnSelectChange(event);
      }
    } catch (error) {
      toast.error("Failed to submit feedback");
    }finally{
      setTaskId('');
    }
  };

  return (
    <ProfessorLayout>
        <div className={styles.component}>
          <div className={styles.form}>
            <div className={styles.heading}>
              <h1>Assesment Center</h1>
            </div>
            <div className={styles.subHeading}>
              <h2>Provide your feedback to your assesment.</h2>
            </div>
            <div className={styles.info}>
              <h3>Choose Task</h3>
              <Select
                size="xs"
                placeholder="Select Task"
                className={styles.tasks}
                onChange={handleOnSelectChange}
              >
                {tasks.map((task) => (
                  <SelectItem key={task.task_id} value={task.task_id}>
                    {task.task_title}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className={styles.info}>
              <Accordion variant="bordered">
                {assesments.map((assesment, index) => (
                  assesment.feedback_id === -1 ? 
                  <AccordionItem
                    key={index}
                    aria-label={assesment.title}
                    title={assesment.student_name}
                    subtitle={`Week ${assesment.week_id}`}
                  >
                    <div className={styles.info}>
                      <h3>Submitted On</h3>
                      <p>{assesment.submitted_date}</p>
                    </div>
                    <div className={styles.info}>
                      <h3>Plag Percentage</h3>
                      <p>{assesment.plag_percentage}</p>
                    </div>
                    <div className={styles.info}>
                      <h3>Documents</h3>
                      <Link type="link" href={assesment.document_url}>
                        Download
                      </Link>
                    </div>
                    <div className={styles.info}>
                      <h3>Student Comments</h3>
                      <p>{assesment.justification_comments}</p>
                    </div>
                    <Textarea
                      labelPlacement="outside"
                      placeholder="Provide your feedback"
                      onChange={(event) => setFeedback(event.target.value)}
                      value={feedback}
                      className={styles.feedback}
                      label="Feedback"
                      size="s"
                    />
                    <Button
                      className={styles.post}
                      variant="bordered"
                      color="primary"
                      onClick={() => handleSubmitFeedback(assesment)}
                    >
                      Post{" "}
                    </Button>
                  </AccordionItem>
                  : null 
                ))}
              </Accordion>
            </div>
          </div>
        </div>
    </ProfessorLayout>
  );
};
