import React from "react";
import { ChatApp } from "../../../components/Chat/Chat";
import { StudentLayout } from "../../../layouts/student/StudentLayout";

export const Chat = () => {

  return (
    <StudentLayout>
      <ChatApp/>
    </StudentLayout>
  );
};
