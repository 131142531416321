import { Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Button} from '@nextui-org/react';
import styles from './CreateOrUpdate.module.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { registration } from '../../../services/user';
import { validateEmail } from '../../../utils/validate';
import { validatePhone } from '../../../utils/validate';

export const CreateOrUpdate = (props) => {

    const { isOpen, onClose , modalType, userType, getUserDetails} = props;
    const [user, setUser] = useState({
    });
    
    const handleSubmit = async () => {
        const _userType = userType === 'Professor' ? 2 : 1;
        const _user = {...user, userType: _userType, userId: modalType !== 'Create' ? props.user.id : -1}
             
          const { data } = await registration(_user);
          if(data.status === "success"){
            toast.success(data.message);
            getUserDetails();
          }else{
            toast.error(data.message);
          } 
      }
    
    useEffect(() => {
        console.log(modalType);
        console.log(props.user);
        modalType !== 'Create' ? setUser(props.user) : setUser({
            firstName:'',
            lastName:'',
            email:'',
            dob:'',
            phone:'',
            gradDate:'',
            password:'',
        });
    },[props.user])
    const isViewing = modalType === "View";
    const isStudent = userType === 'Student' ;

    const isStudentValid = user.firstName && user.lastName && user.email && user.phone && user.gradDate && user.dob && user.password && user.password === user.confirmPassword ;
    const isProfessorValid = user.firstName && user.lastName && user.email && user.phone && user.designation && user.dob && user.password && user.password === user.confirmPassword ;
    const isValid = userType === 'Professor' ? isProfessorValid : isStudentValid;
    return (
        <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose} style={{maxWidth:'56rem'}}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">{ `${modalType} ${userType}`}
              </ModalHeader>
              <ModalBody>
              <div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="text"
            label="First Name"
            name="firstName"
            value={user.firstName}
            errorMessage={!user.firstName && "Firstname required"}
            placeholder="Enter your first name"
            disabled={isViewing}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="text"
            label="LastName"
            name="lastName"
            errorMessage={!user.lastName && "Lastname required"}
            value={user.lastName}
            disabled={isViewing}
            placeholder="Enter your last name"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="number"
            label="Phone"
            errorMessage={!validatePhone(user.phone) && "Phone number should be 10 digits"}
            value={user.phone}
            disabled={isViewing}
            placeholder="Enter your phone number"
            name="phone"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="email"
            label="Email"
            placeholder="Enter your email"
            name="email"
            errorMessage={!validateEmail(user?.email || '') && "Please enter a valid email"}
            value={user.email}
            disabled={isViewing}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="password"
            label="Password"
            name="password"
            errorMessage={!user.password && "Password required"}
            disabled={isViewing}
            placeholder="Enter your password"
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="password"
            label="Confirm Password"
            placeholder="Enter your password"
            errorMessage={
              !( user.confirmPassword === user.password) &&
              "Please verify your password"
            }
            name="confirmPassword"
            disabled={isViewing}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="date"
            label="Date of Birth"
            placeholder="Enter your DOB"
            value={user.dob}
            disabled={isViewing}
            name="dob"
            errorMessage={!user.dob && "Dob required"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div>
        {isStudent ? <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="date"
            label="Graduate Year"
            placeholder="Enter your password"
            name="gradDate"
            disabled={isViewing}
            errorMessage={!user.gradDate && "Gradutation date required"}
            value={user.gradDate}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div> : 
        <div className={styles.subInput}>
          <Input
            labelPlacement="outside"
            type="text"
            label="Designation"
            placeholder="Enter your designation"
            name="designation"
            disabled={isViewing}
            value={user.designation}
            errorMessage={!user.designation && "Designation required"}
            onChange={(e) =>
              setUser({ ...user, [e.target.name]: e.target.value })
            }
          />
        </div> }
      </div>
    </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {modalType !== 'View' && <Button color="primary" isDisabled={!isValid} onPress={handleSubmit}>
                  {modalType}
                </Button> }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    )
}