import React from "react";
import {Card, CardHeader, CardBody, CardFooter, Divider, Image, Chip, Tooltip} from "@nextui-org/react";
import styles from './Card.module.css';
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

export function TaskCard({isProfessor, task, handleOnDelete, handleOnEdit}) {
  return (
    <Card className="max-w-[500px]" >
      <CardHeader className="flex gap-3">
        <div className="flex flex-col">
          <p className="text-md" style={{fontWeight:600}}>{task.task_title}</p>
        </div>
        {isProfessor && 
        <div style={{display:'flex', gap: '10px', marginLeft: '95px' }}>
        <Tooltip content="Edit Task">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => handleOnEdit(task)}>
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete Task">
              <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => handleOnDelete(task)}>
                <DeleteIcon />
              </span>
            </Tooltip>
        </div> }
      </CardHeader>
      <Divider/>
      <CardBody>
        <p>{task.task_description}</p>
      </CardBody>
      <Divider/>
      <CardFooter>
        <div className={styles.footerStatus}>
       <p>
        Status: 
       </p>
       <Chip color="primary"> {task.task_status}</Chip>
       </div>
       <div className={styles.footerDeadline}>
       <p >
        Deadline: 
       </p>
       <Chip>{task.deadline}</Chip>
       </div>
      </CardFooter>
    </Card>
  );
}
