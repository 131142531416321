import { axiosInstance } from "./config"

const getUrl = (url) => {
    return `task/${url}`;
}

export const getTasks = async (filter) => {
    const _filter = {
        filter_professor_id: filter.professorId, 
        filter_student_id: filter.studentId, 
    }
    return await axiosInstance.post(getUrl('gettasks'),_filter);
}

export const createOrUpdateTask = async (payload) => {
    const _payload = {
        task_id:payload.taskId,
        task_title:payload.title,
        task_description:payload.description,
        task_deadline:payload.deadline,
        professor_id:payload.professorId,
        task_status: payload.taskStatus,
        student_ids:payload.studentIds,
        is_deleted:0
    }
    return await axiosInstance.post(getUrl('createorupdatetask'),_payload);
}

export const deleteTask = async (payload) => {
    const _payload = {...payload, is_deleted: 1}
    return await axiosInstance.post(getUrl('createorupdatetask'),_payload);
}

export const getWeeklyReports = async (payload) => {
    return await axiosInstance.post(getUrl('getweeklyreports'),payload);
}

export const createOrUpdateFeedback = async (payload) => {
    return await axiosInstance.post(getUrl('createorupdatefeedback'),payload);
}

export const submitWeeklyReport = async (payload) => {
    return await axiosInstance.post(getUrl('submitweeklyreport'),payload);
}

export const calculatePlaig = async (payload) => {
    return await axiosInstance.post(getUrl('calculateplaig'),payload);
}