import React, { useEffect, useState } from "react";
import styles from "./RLGenerator.module.css";
import { Navbar } from "../../../components/professor/Navbar/Navbar";
import {
  Accordion,
  AccordionItem,
  Textarea,
  Button,
  Link,
} from "@nextui-org/react";
import {
  createOrUpdateRecommendation,
  getRecommendationRequests,
} from "../../../services/recommendation";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";

export const RLGenerator = () => {
  const professorDetails = useSelector((state) => state.user.details);
  const [recommendationRequests, setRecommendationRequests] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");
  const [isShowAll, setIsShowAll] = useState(false);

  const getRecommendationLetterRequests = async () => {
    const payload = {
      professor_id: professorDetails.id,
      student_id: null,
      status: isShowAll ? null : "open",
    };
    const response = await getRecommendationRequests(payload);
    console.log(response);
    if (response.data.status === "success") {
      setRecommendationRequests(response.data.data);
    } else {
      setRecommendationRequests([]);
      setError(response.data.message);
    }
    console.log(response);
  };

  const submitRecommendation = async (recommendationRequest, status) => {
    const payload = {
      ...recommendationRequest,
      professor_comments: feedback,
      status,
    };
    try {
      const response = await createOrUpdateRecommendation(payload);
      if (response.data[0].status === "success") {
        setFeedback("");
        toast.success("Feedback added successfully");
        getRecommendationLetterRequests();
      }
    } catch (error) {
      toast.error("Feedback update failed");
    }
  };

  useEffect(() => {
    if (professorDetails.id) getRecommendationLetterRequests();
  }, [professorDetails.id, isShowAll]);

  return (
    <ProfessorLayout>
      <div className={styles.component}>
        <Button
          variant={isShowAll ? "shadow" : "bordered"}
          color="primary"
          onClick={() => setIsShowAll(!isShowAll)}
        >
          {isShowAll ? "Show only Open" : "Show All"}
        </Button>
        <div className={styles.form}>
          <div className={styles.heading}>
            <h1>Recommendation Letter</h1>
          </div>
          <div className={styles.subHeading}>
            <h2>Request for recommendation letter.</h2>
          </div>
          {error ? (
            <h1>{error}</h1>
          ) : (
            <div className={styles.info}>
              <Accordion variant="bordered">
                {recommendationRequests.map((recommendationRequest) => (
                  <AccordionItem
                    key={recommendationRequest.id}
                    aria-label={recommendationRequest.student_name}
                    title={recommendationRequest.student_name}
                    subtitle={`Submitted on ${recommendationRequest.submitted_on}`}
                  >
                    <div className={styles.info}>
                      <h3>Reason</h3>
                      <p type="link" href=".">
                        {recommendationRequest.student_comments}
                      </p>
                    </div>
                    <div className={styles.info}>
                      <h3>Documents</h3>
                      <Link
                        type="link"
                        href={recommendationRequest.supported_doc_url}
                      >
                        Download
                      </Link>
                    </div>
                    {recommendationRequest.status === "open" && (
                      <>
                        <Textarea
                          labelPlacement="outside"
                          placeholder="Provide your feedback"
                          className={styles.feedback}
                          label="Feedback"
                          size="s"
                          errorMessage={!feedback && "Feedback required"}
                          onChange={(event) => setFeedback(event.target.value)}
                          value={feedback}
                        />
                        <Button
                          className={styles.post}
                          variant="solid"
                          color="primary"
                          isDisabled={!feedback}
                          style={{ marginLeft: "20px" }}
                          onClick={() =>
                            submitRecommendation(
                              recommendationRequest,
                              "accepted"
                            )
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          className={styles.post}
                          variant="bordered"
                          color="danger"
                          isDisabled={!feedback}
                          onClick={() =>
                            submitRecommendation(
                              recommendationRequest,
                              "rejected"
                            )
                          }
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}
        </div>
      </div>
    </ProfessorLayout>
  );
};
