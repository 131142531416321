import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  SelectItem,
  Textarea,
  Button,
  CircularProgress,
  Card,
  CardBody,
  CardFooter,
  Chip,
} from "@nextui-org/react";
import styles from "./WeeklyProgressHub.module.css";
import { StudentLayout } from "../../../layouts/student/StudentLayout";
import {
  calculatePlaig,
  getTasks,
  getWeeklyReports,
  submitWeeklyReport,
} from "../../../services/task";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BlobServiceClient } from "@azure/storage-blob";
import config from "../../../utils/config";
import { Percentage } from "../../../components/Percentage/Percentage";
import { HistoryWPH } from "../../../components/HistoryWPH/HistoryWPH";

export const WeeklyProgressHub = () => {
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState("");
  const [backupTasks, setBackupTasks] = useState([]);
  const studentDetails = useSelector((state) => state.user.details);
  const [task, setTask] = useState({});
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [plaig, setPlaig] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [isHistoryView, setIsHistoryView] = useState(false);

  const uploadImageToAzureBlobStorage = (file) => {
    const blobServiceClient = BlobServiceClient.fromConnectionString(
      config.blob_connectionString
    );
    const containerClient = blobServiceClient.getContainerClient(
      config.blob_weekly_reports_container_name
    );
    const blobName = `${Date.now()}-${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    blockBlobClient.uploadData(file);
    return blockBlobClient.url;
  };

  const getStudentTasks = async () => {
    const filter = {
      professorId: null,
      studentId: studentDetails.id,
    };
    try {
      const response = await getTasks(filter);
      if (response.data.status === "success") {
        const { data } = response.data;
        setTasks(data);
        setBackupTasks(data);
      } else {
        const { message } = response.data;
        toast.error(message);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (studentDetails.id) getStudentTasks();
  }, [studentDetails]);

  const handleFileUpload = async () => {
    const fileURL = uploadImageToAzureBlobStorage(files[0]);
    setFileURL(fileURL);
    const plaigResponse = await calculatePlaig({ url: `${fileURL}.docx` });
    setPlaig(plaigResponse.data.data);
  };

  const handleOnSubmit = async () => {
    try{
    const payload = {
      assignment_id: task.assignment_id,
      doc_url: fileURL,
      justification_comments: description,
      plag_percentage: plaig,
    };
    const response = await submitWeeklyReport(payload);
    if(response.data.status === "success"){
      toast.success("Successfully submitted weekly report");
    }
    else
      toast.warn(response.data.message);
  }
    catch(error){
      toast.error("Failed to submit weekly report");
    }
  };

  const handleOnTaskChange = (event) => {
    const filteredTask = tasks.filter((task) => task.task_id === Number(event.target.value))[0]
    setTask(filteredTask);
  };
  
  const isValid = task && description && fileURL;

  return (
    <StudentLayout>
      <div className={styles.component}>
      {!isHistoryView && <Button variant="shadow" color="primary" onClick={() => setIsHistoryView(true)}>History</Button> }
        <div className={styles.form}>
          <div className={styles.heading}>
            <h1>Weekly Process hub</h1>
          </div>
          <div className={styles.subHeading}>
            <h2>{isHistoryView ? 'History' : 'Current Week'} </h2>
          </div>
          {isHistoryView ? <HistoryWPH studentId={studentDetails.id} professorId={null} taskId={null}/> : <>
          <div className={styles.task}>
            <Select
              className={styles.select}
              label="Tasks"
              placeholder="Select a Task"
              value={task.task_id}
              errorMessage={!task && "Select a task"}
              onChange={handleOnTaskChange}
            >
              {tasks.map((task) => (
                <SelectItem key={task.task_id} value={task.task_id}>
                  {task.task_title}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className={styles.justifyComments}>
            <Textarea
              className={styles.textArea}
              label="Description"
              labelPlacement="outside"
              placeholder="Enter your description"
              errorMessage={!description && "Please enter description"}
              onChange={(event) => setDescription(event.target.value)}
              value={description}
            />
          </div>

          <div className={styles.fileUpload}>
            <Input
              type="file"
              className={styles.input}
              size="xs"
              accept={".docx"}
              errorMessage={!(files.length > 0) && "Upload a file"}
              description={"Accepts only .docx file formats"}
              onChange={(e) => {
                setFiles(e.target.files);
              }}
            />
            <Button
              onClick={handleFileUpload}
              variant="bordered"
              color="primary"
            >
              File Upload
            </Button>
          </div>
          <div className={styles.info}>
              <Percentage percentageValue={Number(Math.floor(plaig))}/>
          </div>
          <div className={styles.submit}>
            <Button variant="shadow" isDisabled={!isValid} color="primary" onClick={handleOnSubmit}>
              Submit
            </Button>
          </div>
          </> }
        </div>
      </div>
    </StudentLayout>
  );
};
