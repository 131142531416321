import React, { useEffect, useState } from "react";
import styles from './StudentDetails.module.css';
import { Navbar } from "../../../components/professor/Navbar/Navbar";
import { StudentDetailsTable } from "../../../components/professor/Table/Table";
import { getStudentDetailsByProfessor } from "../../../services/user";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ProfessorLayout } from "../../../layouts/professor/ProfessorLayout";

export const StudentDetails = () => {

  const [students, setStudents] = useState([]);
  const professorDetails = useSelector((state) => state.user.details);

  const getStudentDetails = async () => {
    console.log(professorDetails);
    const filter = {
      professor_id: professorDetails.id,
  };
    const response = await getStudentDetailsByProfessor(filter)
    console.log(response);
    if(response.data.status === 'success'){
      const { data } = response.data;
      setStudents(data);
    }else{
      const { message } = response.data;
      toast.error(message);
    }
  }
  useEffect(() => {
    if(professorDetails.id)
      getStudentDetails();
  }, [ professorDetails.id]);

  
  return (
    <ProfessorLayout>
      <div className={styles.component}>
      <div className={styles.form}>
            <div className={styles.heading}>
            <h1>
              Students Details
            </h1>
            </div>
            <div className={styles.subHeading}>
              <h2>
                Know About your Students
              </h2>
            </div>
            <div className={styles.info}>
            <StudentDetailsTable students={students} />
            </div>
            </div>
      </div>
    </ProfessorLayout>
  );
}
