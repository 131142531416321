import React from "react";
import styles from "./StudentDetails.module.css";
import { Navbar } from "../../../components/admin/Navbar/Navbar";
import { StudentDetailsTable } from "../../../components/admin/StudentsDetailsTable";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Input } from "@nextui-org/react";
import { getUserDetails } from "../../../services/user";
import { toast } from 'react-toastify';
import { CreateOrUpdate } from "../../../components/admin/CreateOrUpdateUser/CreateOrUpdate";
import { registration } from "../../../services/user";

export const StudentDetails = () => {
  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [backupStudents, setBackStudents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [student,setStudent] = useState({});
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getStudentDetails();
  }, []);

  const getStudentDetails = async () => {
    const filter = {
      filterId: null,
      filterUserType: 1
  };
    const response = await getUserDetails(filter)
    console.log(response);
    if(response.data.status === 'success'){
      const { data } = response.data;
      setStudents(data);
      setBackStudents(data);
    }else{
      const { message } = response.data;
      toast.error(message);
    }
  }

  const handleOnEdit = (student) => {
    setStudent(student);
    setIsModalOpen(true);
    setModalType('Update');
  }

  const handleOnDelete = async (student) => {
    const {id, ..._student} = student;
    const payload = {
      ..._student,
      isDeleted: 1,
      userId: id,
    };
    try {
      const response = await registration(payload);
      if (response.data.status === "success") {
        toast.success("Successfully deleted the user");
        getStudentDetails();
      }
    } catch (error) {
      toast.error("Failed to delete the user");
    }
  } 

  const handleOnView = (student) => {
    setStudent(student);
    setIsModalOpen(true);
    setModalType('View');
  }

  const handleOnCreate = () => {
    setStudent({});
    setIsModalOpen(true);
    setModalType('Create');
  }

  const handleOnSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
    setStudents([...backupStudents].filter((student) => String(student.firstName).includes(value) || 
    String(student.lastName).includes(value) || 
    String(student.dob).includes(value) ||
    String(student.gradDate).includes(value) ||
    String(student.email).includes(value) ||
    String(student.phone).includes(value)
  ));
  }

  const handleOnSearchClear = () => {
    setStudents(backupStudents);
    setSearchText('');
  }
  return (
    <div>
      <div className={styles.actionWrapper}>
        <div className={styles.studentName}>
          <h1>Welcome Admin</h1>
        </div>
        <div className={styles.actionBar}></div>
      </div>
      <div className={styles.container}>
        <div className={styles.navBar}>
          <Navbar />
        </div>
        <div className={styles.component}>
          <div className={styles.form}>
            <div className={styles.heading}>
              <h1>Students Details</h1>
            </div>
            <div className={styles.subHeading}>
              <h2>Know About your Students</h2>
            </div>
            <div className={styles.filter} >
            <div className={styles.create}> 
                <Button variant="solid" size="xs" color="primary" onClick={handleOnCreate}>
                  Create
                </Button>
              </div>
              <div className={styles.search}>
                <div>
                  <Input size="xs"  placeholder="Search for student" value={searchText} onChange={handleOnSearch}/>
                </div>
                <div>
                  <Button variant="bordered" color="primary" onChange={handleOnSearchClear}> Clear </Button>
                </div>
              </div>
            </div>
            <div className={styles.info}>
              <StudentDetailsTable students={students} onView={handleOnView} onEdit={handleOnEdit} onDelete={handleOnDelete} />
            </div>
          </div>
        </div>
      </div>
      <CreateOrUpdate isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} getUserDetails={getStudentDetails} modalType={modalType} user={student} userType={'Student'}/>
    </div>
  );
};
