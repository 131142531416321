import React from "react"
import styles from "./SignInPage.module.css";
import { Image
 } from "@nextui-org/react";
import { SignInForm } from "../../components/SignInForm";
export const SignInPage = () =>{
    return (
        <div className={styles.signInForm}>
        <h2 className={styles.rws}>Welcome Back</h2>
        <p>Sign In</p>
        <div className={styles.wrapper}>
          <div className={styles.image}>
            <Image src={"/signIn.png"} />
          </div>
          <div className={styles.form}>
            <SignInForm />
          </div>
        </div>
        </div>
    )
}