import styles from "./Footer.module.css";
export const Footer = () => {
    function Copyright() {
        return (
            <p className="text-sm text-gray-500">
                {"ISVP Copyright © "}
                <a href="https://uta.edu/" className="text-blue-500 hover:underline">
                    University of Texas at Arlington
                </a>{" "}
                {new Date().getFullYear()}
                {"."}

            </p>
        );
    }

    return (
        <footer className={styles.footer}>

            <div className="container mx-auto">
                <Copyright />
            </div>

        </footer>
    );
};
