import { HelloName } from "../../components/HelloName/HelloName";
import { Navbar } from "../../components/student/Navbar/Navbar";
import styles from "./StudentLayout.module.css";

export const StudentLayout = ({ children }) => {
  return (
    <div>
      <div className={styles.actionWrapper}>
        <div className={styles.professorName}>
          <HelloName />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.navBar}>
          <Navbar />
        </div>
        <div className={styles.component}>{children}</div>
      </div>
    </div>
  );
};
