import React from "react";
import styles from "./EditTask.module.css";
import {
  Input,
  Textarea,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import { createOrUpdateTask } from "../../../services/task";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import { getStudentDetailsByProfessor } from "../../../services/user";

export const EditTask = ({ isOpen, onClose, task, getProfessorTasks }) => {
  console.log(task);
  const [title, setTitle] = useState(task.task_title);
  const [description, setDescription] = useState(task.task_description);
  const [deadline, setDeadline] = useState(task.deadline);
  const professorDetails = useSelector((state) => state.user.details);
  const [studentIds, setStudentIds] = useState(new Set());
  const [students, setStudents] = useState([]);

  const getStudentDetails = async () => {
    console.log(professorDetails);
    const filter = {
      professor_id: professorDetails.id,
    };
    const response = await getStudentDetailsByProfessor(filter);
    console.log(response);
    if (response.data.status === "success") {
      const { data } = response.data;
      setStudents(data);
    } else {
      const { message } = response.data;
      toast.error(message);
    }
  };
  useEffect(() => {
    if (professorDetails.id) getStudentDetails();
  }, [professorDetails.id]);

  const handleOnSubmit = async () => {
    console.log(studentIds);
    const payload = {
      taskId: task.task_id,
      title,
      description,
      deadline,
      professorId: professorDetails.id,
      taskStatus: "open",
      studentIds: Array.from(studentIds).toString(),
    };
    try {
      const response = await createOrUpdateTask(payload);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        getProfessorTasks();
      }
    } catch (error) {
      toast.error("Failed to submit the task");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTitle(task.task_title);
      setDescription(task.task_description);
      setDeadline(task.deadline);
    }
  }, [task, isOpen]);

  const isValid = title && description && deadline;

  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxWidth: "36rem" }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div>
                <div className={styles.container}>
                  <div className={styles.component}>
                    <div className={styles.form}>
                      <div className={styles.heading}>
                        <h1>Edit Task</h1>
                      </div>
                      <div className={styles.subHeading}>
                        <h2>Edit the details to update your task</h2>
                      </div>
                      <div
                        className={styles.info}
                        style={{ marginTop: "40px" }}
                      >
                        <Input
                          labelPlacement="outside"
                          type="text"
                          label="Title"
                          placeholder="Provide the task title"
                          value={title}
                          errorMessage={!title && "Title required"}
                          onChange={(event) => setTitle(event.target.value)}
                        />
                      </div>
                      <div className={styles.info}>
                        <Textarea
                          labelPlacement="outside"
                          placeholder="Provide the task description"
                          label="Description"
                          size="s"
                          value={description}
                          errorMessage={!description && "Description required"}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                        />
                      </div>
                      <div className={styles.info}>
                        <MultiSelect
                          users={students}
                          values={studentIds}
                          setValues={setStudentIds}
                        />
                      </div>
                      <div className={styles.info}>
                        <Input
                          type="date"
                          label="Deadline"
                          placeholder="Please choose the deadline date"
                          errorMessage={!deadline && "Deadline required"}
                          value={deadline}
                          onChange={(event) => setDeadline(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button color="primary" isDisabled={!isValid} onPress={handleOnSubmit}>
                Edit
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
