import { Chat } from '../pages/Professor/Chat';
import { MyAccount } from '../pages/Professor/MyAccount';
import { StudentDetails } from '../pages/Professor/StudentDetails';
import { AssesmentCenter, RLGenerator } from '../pages/Professor';
import { CreateTask } from '../pages/Professor/CreateTask/CreateTask';
import { WeeklyProgressHistory } from '../pages/Professor/WeeklyProgressHistory/WeeklyProgressHistory';

export const professorRoutes = () => { 
    const path = '/professor';
    return [
    {
      path: `${path}/studentsDetails`,
      element: <StudentDetails/>
    },
    {
      path: `${path}/assesmentCenter`,
      element: <AssesmentCenter/>
    },
    {
      path: `${path}/rlGenerator`,
      element: <RLGenerator/>
    },{
      path: `${path}/chat`,
      element: <Chat/>
    },{
      path: `${path}/myAccount`,
      element: <MyAccount/>
    },
    {
      path: `${path}/createTask`,
      element: <CreateTask/>
    },
    {
      path:  `${path}/weeklyProgressHistory`,
      element: <WeeklyProgressHistory/>
    }
  
  ]}