import { Listbox, ListboxItem} from "@nextui-org/react";
import {IconWrapper} from "../../../icons/IconWrapper";
import {ItemCounter} from "./ItemCounter";
import {BugIcon} from "../../../icons/BugIcon";
//import {PullRequestIcon} from "../../../icons/PullRequestIcon";
import {ChatIcon} from "../../../icons/ChatIcon";
//import {PlayCircleIcon} from "../../../icons/PlayCircleIcon";
import {LayoutIcon} from "../../../icons/LayoutIcon";
import {TagIcon} from "../../../icons/TagIcon";
import {UsersIcon} from "../../../icons/UsersIcon";
import {WatchersIcon} from "../../../icons/WatchersIcon";
import {BookIcon} from "../../../icons/BookIcon";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();
    return (

        <Listbox
      aria-label="User Menu"
      onAction={(key) => navigate(`/student/${key}`)}
      className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxItem
        key="dashboard"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-success/10 text-success">
            <BugIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Tasks
      </ListboxItem>
      <ListboxItem
        key="calendar"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
             <LayoutIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Calendar
      </ListboxItem>
      <ListboxItem
        key="weeklyProcessHub"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
           <TagIcon/>
          </IconWrapper>
        }
      >
        Weekly Process Hub
      </ListboxItem>
      {/*<ListboxItem
        key="pull_requests"
        endContent={<ItemCounter number={6} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <PullRequestIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Pull Requests
      </ListboxItem>*/}
      <ListboxItem
        key="supervisorInfo"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
             <WatchersIcon />
          </IconWrapper>
        }
      >
        Supervisor Info
      </ListboxItem>
      <ListboxItem
        key="immigration"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-danger/10 text-danger dark:text-danger-500">
            <BookIcon />
          </IconWrapper>
        }
      >
        Immigration
      </ListboxItem>

      <ListboxItem
        key="chat"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <ChatIcon className="text-lg " />
          </IconWrapper>
        }
      >
        Chat
      </ListboxItem>
      <ListboxItem
        key="myAccount"
        endContent={<ItemCounter />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
           <UsersIcon />
          </IconWrapper>
        }
      >
        My Account
      </ListboxItem>
    </Listbox>
    )
}