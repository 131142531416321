import React from "react";
import styles from "./ContactUs.module.css";
import { Button, Image, Input, Textarea } from "@nextui-org/react";
import { useState } from "react";
import { createQuery } from "../../services/query";
import { toast } from "react-toastify";

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const handleOnSubmit = async () => {
    try {
      const payload = {
        name,
        email,
        description,
      };
      console.log(payload);
      const response = await createQuery(payload);
      if (response.data.status === "success")
        toast.success(response.data.message);
    } catch (error) {
      toast.error("Failed to submit the details");
    } finally {
      setDescription("");
      setEmail("");
      setName("");
    }
  };
  const disable = name && email && description;
  return (
    <div className={styles.contactUs}>
      <h1>How can we help you?</h1>
      <p className={styles.description}>
        Thank you for your interest in ISVP. Please use this form to contact us.
        We will get back to you as soon as we can.
      </p>
      <div className={styles.contactUsInfo}>
        <div className={styles.image}>
          <Image src={"/contact-us.webp"} width={"500px"} height={"300px"} />
        </div>
        <div className={styles.info}>
          <div className={styles.form}>
            <Input
              type="text"
              className={styles.name}
              value={name}
              onChange={(event) => setName(event.target.value)}
              label="Name"
              size="s"
            />
            <Input
              type="email"
              className={styles.email}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="Email"
              size="s"
            />
            <Textarea
              label="Description"
              className={styles.description}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              size="s"
            />
            <Button
              color="primary"
              variant="bordered"
              className={styles.submit}
              onClick={handleOnSubmit}
              size="s"
              isDisabled={!disable}
              style={{ cursor: !disable ? "not-allowed" : "pointer" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
