import { axiosInstance } from "./config"

const getUrl = (url) => {
    return `user/${url}`;
}

export const registration = async (user) => {
    return await axiosInstance.post(getUrl('createorupdateuser'), user);
}

export const signIn = async (user) => {
    return await axiosInstance.post(getUrl('login'), user);
}

export const forgotPassword = async (email) => {
    const user = {
        "email": email
    }
    console.log("inside forgot password")
    return await axiosInstance.post(getUrl('forgotpassword'), user);
}

export const addSuperVisorDetails = async (payload) => {
    const _payload = {
        "professor_id": payload.professorId,
        "student_email": payload.studentEmailId
    }
    return await axiosInstance.post(getUrl('addsupervisiondetails'), _payload);
}

export const getUserDetails = async (filter) => {
    const _filter = {
        filterId: filter.filterId,
        filterUserType: filter.filterUserType
    };
    console.log(_filter);
    return await axiosInstance.post(getUrl('getuserdetails'), _filter);
}

export const getSuperVisorDetails = async (studentId) => {
    const _studentId = '2';
    return await axiosInstance.get(getUrl(`getsupervisordetails/${studentId}`));
}

export const getStudentDetailsByProfessor = async (payload) => {
    console.log(payload);
    return await axiosInstance.post(getUrl('getstudentdetailsbyprofessor'), payload);
}