import { axiosInstance } from "./config"

const getUrl = (url) => {
    return `query/${url}`;
}

export const createQuery = async (payload) => {
    return await axiosInstance.post(getUrl('createquery'), payload);
} 

export const upateQueryStatus = async (payload) => {
    return await axiosInstance.post(getUrl('updatequerystatus'), payload);
}

export const getQueries = async () => {
    return await axiosInstance.get(getUrl('getqueries'));
}