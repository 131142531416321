import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, Image } from "@nextui-org/react";
import { useSelector } from "react-redux";

export const Header = () => {
  const isSignedIn = useSelector((state) => state.auth.isSignedIn)
  return (
    <Navbar>
      <div onClick={() => window.location.href = "/"}>
        <Image style={{ borderRadius: 0 }} src={"/logo.png"} width={40} height={40} />
      </div>
      <NavbarBrand onClick={() => window.location.href = "/"} >
        <p className="font-bold text-inherit">International Students Volunteer Program</p>
      </NavbarBrand>
      <NavbarContent className="sm:flex gap-4" justify="center">
        <NavbarItem isActive>
          <Link href="/aboutUs" aria-current="page">
            About Us
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="/contactUs">
            Contact Us
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link href="https://lbk9537.uta.cloud/blog/empowering-international-students-a-dive-into-our-volunteer-program-project/" target="_blank" aria-current="page">
            Blog
          </Link>
        </NavbarItem>
      </NavbarContent> {!isSignedIn ? 
        <NavbarContent justify="end">
          <NavbarItem className="lg:flex">
            <Link href="/signIn">Sign In</Link>
          </NavbarItem>
          <NavbarItem>
            <Button as={Link} color="primary" href={'/registration'} variant="flat">
              Sign Up
            </Button>
          </NavbarItem>
        </NavbarContent> : 
         <NavbarContent justify="end">
         <NavbarItem>
           <Button as={Link} color="primary" href={'/'} variant="flat">
             Log Out
           </Button>
         </NavbarItem>
       </NavbarContent>
        }
    </Navbar>
  );
}